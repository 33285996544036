import { render, staticRenderFns } from "./CategoryEditPage.vue?vue&type=template&id=3ce8e70a&scoped=true&"
import script from "./CategoryEditPage.vue?vue&type=script&lang=js&"
export * from "./CategoryEditPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ce8e70a",
  null
  
)

export default component.exports